import React, { Component } from "react"
import Img from "gatsby-image"

export default props => (
  <>
    {props.node.frontmatter.thumbnail ? (
      <ContentWithImage props={props} />
    ) : (
      <ContentNoImage props={props} />
    )}
  </>
)

class ContentNoImage extends Component {
  render() {
    const { props } = this.props
    return (
      <article className="testimonial-card">
        <div className="row">
          <div className="col-12">
            <h4 className="testimonial-title">
              {props.node.frontmatter.title}{" "}
              {props.node.frontmatter.link.length > 2 && (
                <>
                  {" "}
                  -{" "}
                  <a href={props.node.frontmatter.link} target="_blank">
                    {props.node.frontmatter.link}
                  </a>
                </>
              )}
            </h4>
          </div>
        </div>
        <div className="row testimonial-content">
          <div className="col-12 ">
            <blockquote
              dangerouslySetInnerHTML={{ __html: props.node.html }}
            ></blockquote>
          </div>
        </div>
      </article>
    )
  }
}

class ContentWithImage extends Component {
  render() {
    const { props } = this.props
    return (
      <article className="testimonial-card">
        <div className="row">
          <div className="col-12">
            <h4 className="testimonial-title">
              {props.node.frontmatter.title}{" "}
              {props.node.frontmatter.link.length > 2 && (
                <>
                  {" "}
                  -{" "}
                  <a href={props.node.frontmatter.link} target="_blank">
                    {props.node.frontmatter.link}
                  </a>
                </>
              )}
            </h4>
          </div>
        </div>
        <div className="row testimonial-content">
          <div className="col-3 testimonial-img-col">
            <Img
              sizes={{
                ...props.node.frontmatter.thumbnail.childImageSharp.fluid,
                aspectRatio: 1 / 1,
              }}
              imgStyle={{ objectFit: "contain" }}
              alt=""
            />
          </div>
          <div className="col-9">
            <blockquote
              dangerouslySetInnerHTML={{ __html: props.node.html }}
            ></blockquote>
          </div>
        </div>
      </article>
    )
  }
}
