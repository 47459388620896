import React from "react"

import { graphql } from "gatsby"

import TestimonialsCard from "../components/testimonialsCard"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const TestimonialsPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const testimonials = data.testimonials.edges

  return (
    <Layout title={siteTitle}>
      <SEO
        description={data.page.frontmatter.description}
        title={data.page.frontmatter.metaTitle}
      />
      <div className="post-content page-template">
        <div className="post-content-body">
          <h1>{data.page.frontmatter.heading}</h1>
          <div dangerouslySetInnerHTML={{ __html: data.page.html }} />
        </div>
        <div className="testimonials-feed">
          {testimonials.map(({ node }) => {
            return (
              <TestimonialsCard
                key={node.frontmatter.title}
                node={node}
                postClass={`testimonial`}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const TESTIMONIALS_PAGE_QUERY = graphql`
  query {
    site: site {
      siteMetadata {
        title
        author
      }
    }
    page: markdownRemark(
      frontmatter: { templateKey: { eq: "testimonials-page" } }
    ) {
      frontmatter {
        metaTitle
        description
        heading
      }
      html
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonial" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            link
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 165) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TestimonialsPage
